import { NgModule, APP_INITIALIZER } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { Environment} from "../environments/environment";
import { AppComponent } from './app.component'
import { LibraryModule, InitializeService as LibraryInitializeService } from 'repapp-common-lib'

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		IonicModule,
		LibraryModule.forRoot(Environment)
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: (i:LibraryInitializeService) => { return () => i.init()},
			deps: [LibraryInitializeService]
		},
		{
			provide: RouteReuseStrategy,
			useClass: IonicRouteStrategy
		},
	],
	bootstrap: [AppComponent]
})

export class AppModule { }
