import { Component } from '@angular/core';
import { DeviceInfoService } from 'repapp-common-lib'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private deviceSer: DeviceInfoService,
  ) {
    this.deviceSer.setEnv("web")
		this.deviceSer.setPlatform("web")
		this.deviceSer.setModel("brw")
		this.deviceSer.setUuid("xa91")
  }
}
